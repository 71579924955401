import React from 'react'
import { PageLoadEnableRender } from './PageLoadEnableRender'
import { PageLoadDisableRender } from './PageLoadDisableRender'

const PageLoadRender = ({ isLoad }) => {
    if (isLoad === true) {
        return (<PageLoadEnableRender />)
    } else {
        return (<PageLoadDisableRender />)
    }
}

export { PageLoadRender }
