import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Avatar } from "../Avatar/Avatar"
import { MENU_ACCOUNTING_ID } from "../../../../../infrastructure/constants/menu"
import "bulma-modal-fx/dist/css/modal-fx.min.css"
import { modal } from "../../commons/modal"

const SubMenu = ({ router, menu, submenu }) => {
    if (submenu.submenuLeft && submenu.submenuRight) {
        return (
            <div className="columns">
                <div className="column">
                    <p className="menu-label" style={{ color: "#000", fontSize: "inherit" }}>
                        {submenu.submenuLeft.name}
                    </p>
                    <ul className="menu-list">
                        {submenu.submenuLeft.functionalities.map((functionality) =>
                            <li key={functionality.name}>
                                <a onClick={(_) => { router(menu.name, functionality.path) }}>
                                    {functionality.name}
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
                <div className="column">
                    <p className="menu-label" style={{ color: "#000", fontSize: "inherit" }}>
                        {submenu.submenuRight.name}
                    </p>
                    <ul className="menu-list">
                        {submenu.submenuRight.functionalities.map((functionality) =>
                            <li key={functionality.name}>
                                <a onClick={(_) => { router(menu.name, functionality.path) }}>
                                    {functionality.name}
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        )
    } else if (submenu.submenuLeft) {
        return (
            <div className="columns">
                <div className="column">
                    <p className="menu-label" style={{ color: "#000", fontSize: "inherit" }}>
                        {submenu.submenuLeft.name}
                    </p>
                    <ul className="menu-list">
                        {submenu.submenuLeft.functionalities.map((functionality) =>
                            <li key={functionality.name}>
                                <a onClick={(_) => { router(menu.name, functionality.path) }}>
                                    {functionality.name}
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        )
    }
}

const Menu = ({ router, menu }) => {
    return (
        <div id={"menu-" + menu.ID} style={{ display: "none" }}>
            <aside className="menu">
                {menu.submenus.map((submenu) =>
                    <div key={submenu.submenuLeft.ID}>
                        <SubMenu
                            router={router}
                            menu={menu}
                            submenu={submenu} />
                    </div>
                )}
            </aside>
        </div>
    )
}


function MenuDesktop({ listMenus, handleOpenModalSelectCompany }) {
    const [listMenusCustom, setListMenusCustom] = useState([])
    const [menuID, setMenuID] = useState("")

    const historySession = useSelector(state => state.session.history)
    const accountSession = useSelector(state => state.session.account)
    const companySession = useSelector(state => state.session.company)

    useEffect(() => {
        init()
    }, [listMenus])

    useEffect(() => {
        initMenuSelect()
    }, [listMenusCustom])

    function init() {
        if (listMenus && listMenus.length > 0) {
            const listMenusClone = [].concat(listMenus)

            let listMenusC = []

            listMenusClone.map(menu => {
                let submenus = []
                let submenu = {}

                let menuClone = {
                    ...menu
                }

                for (let smi = 0; smi < menuClone.submenus.length; smi++) {
                    const submenuI = menuClone.submenus[smi]

                    if (menu.submenus.length === 1) {
                        submenu.submenuLeft = submenuI
                        submenus.push(submenu)
                        submenu = {}
                    } else if (smi % 2 === 0) {
                        submenu.submenuLeft = submenuI

                        if (smi + 1 === menu.submenus.length) {
                            submenus.push(submenu)
                            submenu = {}
                        }
                    } else {
                        submenu.submenuRight = submenuI
                        submenus.push(submenu)
                        submenu = {}
                    }
                }

                menuClone.submenus = submenus
                listMenusC.push(menuClone)
            })

            setListMenusCustom(listMenusC)
        }
    }

    function initMenuSelect() {
        if (listMenusCustom && listMenusCustom.length > 0) {
            document.getElementById("menu-" + MENU_ACCOUNTING_ID).style.display = "block"
            document.getElementById("menu-label-" + MENU_ACCOUNTING_ID).classList.add("menu-select")
            setMenuID(MENU_ACCOUNTING_ID)
        }
    }

    function router(_, nameRouter) {
        historySession.push(nameRouter)
        menuBigClose()
        menuBurger()
    }

    function mouse(menu) {
        menuOpen(menu)
    }

    function mouseOut(menu) {
        menuClose(menu)
    }

    function menuOpen(menu) {
        var element = document.getElementById(menu)
        const name = 'is-active'
        element.classList.remove(name)
        element.classList.add(name)
    }

    function menuClose(menu) {
        document.getElementById(menu).classList.remove("is-active")
    }

    function handleLogout(_) {
        menuBigClose()
        window.location.href = 'https://accounts.b3bnb.com/logout?service=paperless-brazil'
    }

    function menuBurger() {
        const el = document.getElementsByClassName("navbar-burger")[0]

        const target = el.dataset.target;
        const $target = document.getElementById(target);

        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');
    }

    function menuBigOpen() {
        modal.open("modalMenuBig")
    }

    function menuBigClose() {
        modal.close("modalMenuBig")
    }

    function menuBigSelect(menuIDSelect) {
        if (menuID) {
            document.getElementById("menu-" + menuID).style.display = "none"
            document.getElementById("menu-label-" + menuID).classList.remove("menu-select")
        }

        document.getElementById("menu-" + menuIDSelect).style.display = "block"
        document.getElementById("menu-label-" + menuIDSelect).classList.add("menu-select")

        setMenuID(menuIDSelect)
    }

    return (
        <>
            <div id="modalMenuBig" className="modal modal-full-screen modal-fx-fadeInScale">
                <div className="modal-content modal-card">
                    <header className="modal-card-head" style={{ backgroundColor: "#fff", borderBottom: "unset" }}>
                        <div style={{ fontSize: "18px", backgroundColor: "#265D26", padding: "2px 5px", color: "#fff" }}>B3BNB</div>
                        <div style={{ marginLeft: "5px", padding: "5px", color: "#000" }}>Inbox</div>
                        <p className="modal-card-title" style={{ marginLeft: "10px" }}></p>
                        <button className="button is-small is-white" onClick={_ => menuBigClose()}>
                            <span className="icon is-small">
                                <i className="fas fa-times" style={{ fontSize: "20px" }}></i>
                            </span>
                        </button>
                    </header>
                    <section className="modal-card-body">
                        <div className="columns">
                            <div className="column is-8">
                                <h1 className="title" style={{ marginLeft: "5px" }}>Menu</h1>
                                <div className="columns">
                                    <div className="column is-3" style={{ marginLeft: "5px" }}>
                                        <aside className="menu">
                                            {listMenusCustom.map((menu) =>
                                                <p key={menu.ID} className="menu-label" id={"menu-label-" + menu.ID} style={{ fontSize: "18px", color: "#000", marginBottom: "0px", cursor: "pointer", textTransform: "inherit" }} onClick={_ => menuBigSelect(menu.ID)}>
                                                    {menu.name}
                                                </p>
                                            )}
                                        </aside>
                                    </div>
                                    <div className="column is-5">
                                        {listMenusCustom.map((menu) =>
                                            <div key={"menu-key" + menu.ID}>
                                                <Menu
                                                    router={router}
                                                    menu={menu} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="tile is-ancestor">
                                    <div className="tile is-vertical">
                                        <div className="tile">
                                            <div className="tile is-parent is-vertical">
                                                <article className="tile is-child notification is-primary" style={{ backgroundColor: "#e7f6ff", color: "#000" }}>
                                                    <p className="title">
                                                        <strong>{accountSession.name}</strong>
                                                    </p>
                                                    <p className="subtitle">
                                                        {accountSession.email}
                                                    </p>
                                                </article>
                                                <article className="tile is-child notification is-warning" style={{ backgroundColor: "#ffeff3", color: "#000" }}>
                                                    <p className="title">
                                                        <strong>{companySession ? companySession.socialName : "Empresa"}</strong>
                                                    </p>
                                                    <p className="subtitle">{companySession ? companySession.document : ""}</p>
                                                </article>
                                                <article className="tile is-child notification is-warning" style={{ backgroundColor: "#fff8dd", color: "#000" }}>
                                                    <p className="title">
                                                        <strong>Suporte</strong>
                                                    </p>
                                                    <p className="subtitle">(81) 3033-5347</p>
                                                </article>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            <div className="navbar-menu" id="navbarExampleTransparentExample">
                <div className="navbar-start">
                    <div className="navbar-item">
                        <button className="button is-small is-rounded" style={{ fontWeight: "600" }} onClick={_ => handleOpenModalSelectCompany()}>
                            <span className="icon">
                                <i className="fas fa-exchange-alt"></i>
                            </span>
                            <span>{companySession ? companySession.socialName : "Sem Clientes"}</span>
                        </button>
                    </div>
                    <div className="navbar-item">
                        <button id="buttonBigMenu" className="button is-small is-rounded" onClick={_ => menuBigOpen()}>
                            <span className="icon">
                                <i className="fas fa-th-large" style={{ fontSize: "17px" }}></i>
                            </span>
                        </button>
                    </div>
                </div>
                <div className="navbar-end">
                    <div className="navbar-item has-dropdown" id="services" onMouseOver={(_) => { mouse("services") }} onMouseOut={(_) => { mouseOut("services") }}>
                        <a className="navbar-item">
                            <button className="button is-small is-rounded" style={{ padding: "15px" }}>
                                <span className="icon">
                                    <i className="fas fa-dice-d6" style={{ fontSize: "20px" }}></i>
                                </span>
                            </button>
                        </a>
                        <div className="navbar-dropdown is-boxed is-right">
                            <a className="navbar-item is-active">
                                Produtos
                            </a>
                            <a className="navbar-item" href="https://b3bnb.com">
                                Site b3bnb
                            </a>
                        </div>
                    </div>
                    <div className="navbar-item has-dropdown navend-user" id="user" onMouseOver={(_) => { mouse("user") }} onMouseOut={(_) => { mouseOut("user") }}>
                        <div className="navbar-item">
                            <Avatar
                                name={accountSession.name} />
                        </div>
                        <div className="navbar-dropdown is-boxed is-right">
                            <a className="navbar-item">
                                <strong>{accountSession.name}</strong>
                            </a>
                            <a className="navbar-item">
                                {accountSession.email}
                            </a>
                            <hr className="navbar-divider" />
                            <a className="navbar-item">
                                Minha Conta
                            </a>
                            <a className="navbar-item" onClick={(_) => { handleLogout(_) }}>
                                Sair
                            </a>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export {
    MenuDesktop
}
