import rp from 'request-promise'
import {
    b3bnbAccountApiV1Config, b3bnbAccountPlanApiV1Config,
    b3bnbCompanyBrazilApiV1Config, paperlessSecurityBrazilApiV1Config,
    b3bnbAccountPlanInvoiceApiV1Config, b3bnbAccountPlanInvoiceCieloSuperLinkApiV1Config,
    b3bnbAccountPlanInvoiceDepositApiV1Config, govNativeMonthlyAccountingClosingApiV1Config,
    paperlessAccountantOfficeBrazilApiV1Config, paperlessInvitationBrazilApiV1Config,
    govNativeIdentificationDocumentBrazilApiV1Config, localizationBrazilApiV1Config

} from './config'

function graphqlRequest(uri, body, variables) {
    body = {
        query: body
    }

    if (variables) {
        body.variables = variables
    }

    let options = {
        method: 'POST',
        uri,
        body,
        json: true
    }

    return rp(options)
}

async function graphqlB3bnbAccountApiV1(body, variables) {
    try {
        return await graphqlRequest(b3bnbAccountApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlLocalizationBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(localizationBrazilApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlGovNativeIdentificationDocumentBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(govNativeIdentificationDocumentBrazilApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlB3bnbAccountPlanApiV1(body, variables) {
    try {
        return await graphqlRequest(b3bnbAccountPlanApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlB3bnbCompanyBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(b3bnbCompanyBrazilApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlPaperlessSecurityBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(paperlessSecurityBrazilApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlB3bnbAccountPlanInvoiceApiV1(body, variables) {
    try {
        return await graphqlRequest(b3bnbAccountPlanInvoiceApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlB3bnbAccountPlanInvoiceCieloSuperLinkApiV1(body, variables) {
    try {
        return await graphqlRequest(b3bnbAccountPlanInvoiceCieloSuperLinkApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlB3bnbAccountPlanInvoiceDepositApiV1(body, variables) {
    try {
        return await graphqlRequest(b3bnbAccountPlanInvoiceDepositApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlGovNativeMonthlyAccountingClosingApiV1(body, variables) {
    try {
        return await graphqlRequest(govNativeMonthlyAccountingClosingApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlPaperlessAccountantOfficeBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(paperlessAccountantOfficeBrazilApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlPaperlessInvitationBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(paperlessInvitationBrazilApiV1Config.URI_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

function getInternalServerError() {
    const error = { message: 'Erro de comunicação' }
    return error
}

function getError(obj) {
    //error graphql
    if (obj.errors) {
        return getInternalServerError()
        //erro network
    } else if (obj.message) {
        return getInternalServerError()
        // error infrastructure
    } else if (obj.data[Object.keys(obj.data)[0]].error) {
        return obj.data[Object.keys(obj.data)[0]].error
    }

    return null
}

export {
    getError,
    graphqlB3bnbAccountApiV1,
    graphqlLocalizationBrazilApiV1,
    graphqlGovNativeIdentificationDocumentBrazilApiV1,
    graphqlB3bnbAccountPlanApiV1,
    graphqlB3bnbCompanyBrazilApiV1,
    graphqlPaperlessSecurityBrazilApiV1,
    graphqlB3bnbAccountPlanInvoiceApiV1,
    graphqlB3bnbAccountPlanInvoiceCieloSuperLinkApiV1,
    graphqlB3bnbAccountPlanInvoiceDepositApiV1,
    graphqlGovNativeMonthlyAccountingClosingApiV1,
    graphqlPaperlessAccountantOfficeBrazilApiV1,
    graphqlPaperlessInvitationBrazilApiV1
}
