// const b3bnbAccountCookieApiV1Config = {
//     URL: 'http://ac.localhost.com:8002',
// }

// const localizationBrazilApiV1Config = {
//     URI_GRAPHQL: 'http://localhost:8001/v1/graphql'
// }

// const govNativeSaleNFCeReportBrazilRestApiV1Config = {
//     URL: 'http://localhost:8038/v1',
// }

// const b3bnbAccountApiV1Config = {
//     URI_GRAPHQL: 'http://localhost:8000/v1/graphql'
// }

// const b3bnbCompanyBrazilApiV1Config = {
//     URI_GRAPHQL: 'http://localhost:8010/v1/graphql'
// }

// const govNativeIdentificationDocumentBrazilApiV1Config = {
//     URI_GRAPHQL: 'http://localhost:8035/v1/graphql'
// }

// const b3bnbAccountPlanInvoiceApiV1Config = {
//     URI_GRAPHQL: 'http://localhost:8028/v1/graphql'
// }

// const b3bnbAccountPlanInvoiceCieloSuperLinkApiV1Config = {
//     URI_GRAPHQL: 'http://localhost:8029/v1/graphql'
// }

// const b3bnbAccountPlanInvoiceDepositApiV1Config = {
//     URI_GRAPHQL: 'http://localhost:8032/v1/graphql'
// }

// const b3bnbAccountPlanApiV1Config = {
//     URI_GRAPHQL: 'http://localhost:8008/v1/graphql'
// }

// const govNativeMonthlyAccountingClosingApiV1Config = {
//     URI_GRAPHQL: 'http://localhost:8037/v1/graphql'
// }

// const paperlessSecurityBrazilApiV1Config = {
//     URI_GRAPHQL: 'http://localhost:8040/v1/graphql'
// }

// const paperlessAccountantOfficeBrazilApiV1Config = {
//     URI_GRAPHQL: 'http://localhost:8039/v1/graphql'
// }

// const paperlessInvitationBrazilApiV1Config = {
//     URI_GRAPHQL: 'http://localhost:8041/v1/graphql'
// }

const b3bnbAccountCookieApiV1Config = {
    URL: 'https://accountcookie.b3bnb.com',
}

const localizationBrazilApiV1Config = {
    URI_GRAPHQL: 'https://apis.b3bnb.com/localization-brazil-graphql-api/v1/graphql'
}

const b3bnbAccountApiV1Config = {
    URI_GRAPHQL: 'https://apis.b3bnb.com/b3bnb-account-graphql-api/v1/graphql'
}

const b3bnbCompanyBrazilApiV1Config = {
    URI_GRAPHQL: 'https://apis.b3bnb.com/b3bnb-company-brazil-graphql-api/v1/graphql'
}

const govNativeIdentificationDocumentBrazilApiV1Config = {
    URI_GRAPHQL: 'https://apis.b3bnb.com/gov-native-identification-document-brazil-graphql-api/v1/graphql'
}

const b3bnbAccountPlanInvoiceApiV1Config = {
    URI_GRAPHQL: 'https://apis.b3bnb.com/b3bnb-account-plan-invoice-graphql-api/v1/graphql'
}

const b3bnbAccountPlanInvoiceCieloSuperLinkApiV1Config = {
    URI_GRAPHQL: 'https://apis.b3bnb.com/b3bnb-account-plan-invoice-cielo-super-link-graphql-api/v1/graphql'
}

const b3bnbAccountPlanInvoiceDepositApiV1Config = {
    URI_GRAPHQL: 'https://apis.b3bnb.com/b3bnb-account-plan-invoice-deposit-graphql-api/v1/graphql'
}

const b3bnbAccountPlanApiV1Config = {
    URI_GRAPHQL: 'https://apis.b3bnb.com/b3bnb-account-plan-graphql-api/v1/graphql'
}

const govNativeMonthlyAccountingClosingApiV1Config = {
    URI_GRAPHQL: 'https://apis.b3bnb.com/gov-native-monthly-accounting-closing-brazil-graphql-api/v1/graphql'
}

const paperlessSecurityBrazilApiV1Config = {
    URI_GRAPHQL: 'https://apis.b3bnb.com/paperless-security-brazil-graphql-api/v1/graphql'
}

const paperlessAccountantOfficeBrazilApiV1Config = {
    URI_GRAPHQL: 'https://apis.b3bnb.com/paperless-accountant-office-brazil-graphql-api/v1/graphql'
}

const paperlessInvitationBrazilApiV1Config = {
    URI_GRAPHQL: 'https://apis.b3bnb.com/paperless-invitation-brazil-graphql-api/v1/graphql'
}

export {
    b3bnbAccountCookieApiV1Config,
    localizationBrazilApiV1Config,
    b3bnbAccountApiV1Config,
    b3bnbCompanyBrazilApiV1Config,
    govNativeIdentificationDocumentBrazilApiV1Config,
    paperlessSecurityBrazilApiV1Config,
    b3bnbAccountPlanInvoiceApiV1Config,
    b3bnbAccountPlanInvoiceCieloSuperLinkApiV1Config,
    b3bnbAccountPlanInvoiceDepositApiV1Config,
    b3bnbAccountPlanApiV1Config,
    govNativeMonthlyAccountingClosingApiV1Config,
    paperlessAccountantOfficeBrazilApiV1Config,
    paperlessInvitationBrazilApiV1Config
}
