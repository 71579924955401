import {
    graphqlPaperlessSecurityBrazilApiV1, graphqlPaperlessAccountantOfficeBrazilApiV1,
    graphqlB3bnbCompanyBrazilApiV1
} from '../../../../../../infrastructure/api/graphql/graphql'
import {
    accountFunctionalitiesMenuPermissionQuery, accountantOfficeCompaniesQuery,
    companiesBrazilByIDsQuery, companyBrazilQuery
} from './graphql/query'

async function listMenus(accountID, accountPlanID) {
    const payload = {
        accountID,
        accountPlanID
    }

    return await graphqlPaperlessSecurityBrazilApiV1(accountFunctionalitiesMenuPermissionQuery, payload)
}

async function listAccountantOfficeCompanies(accountantOfficeID) {
    const payload = {
        accountantOfficeID
    }

    return await graphqlPaperlessAccountantOfficeBrazilApiV1(accountantOfficeCompaniesQuery, payload)
}

async function listCompaniesBrazilByIDs(companiesID) {
    const payload = {
        companiesID
    }

    return await graphqlB3bnbCompanyBrazilApiV1(companiesBrazilByIDsQuery, payload)
}

async function getCompanyBrazil(ID) {
    const payload = {
        ID
    }

    return await graphqlB3bnbCompanyBrazilApiV1(companyBrazilQuery, payload)
}

const Controller = {
    listMenus,
    listAccountantOfficeCompanies,
    listCompaniesBrazilByIDs,
    getCompanyBrazil
}

export {
    Controller
}