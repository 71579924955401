import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Controller } from './controller/controller'
import { getError } from '../../../../../infrastructure/api/graphql/graphql'
import { companyAdd } from "../../store/ducks/session"
import { modal } from "../../commons/modal"
import { toast } from "../../commons/toast"
import { NavBarRender } from './NavBarRender'
import './style.css'

function NavBar() {
    const dispatch = useDispatch()

    const isLogged = useSelector(state => state.session.isLogged)
    const [planStatusID, setPlanStatusID] = useState("")
    const accountSession = useSelector(state => state.session.account)
    const sessionAccountPlan = useSelector(state => state.session.accountPlan)
    const accountantOfficeSession = useSelector(state => state.session.accountantOffice)
    const [listMenus, setListMenus] = useState([])
    const [listCompaniesBrazil, setListCompaniesBrazil] = useState([])


    useEffect(() => {
        verifyListMenus()
    }, [isLogged])

    useEffect(() => {
        verifyListMenus()
    }, [sessionAccountPlan])

    async function verifyListMenus() {
        if (isLogged) {
            setPlanStatusID(sessionAccountPlan.planStatusID)

            const listMenusResponse = await Controller.listMenus(accountSession.ID, sessionAccountPlan.ID)
            const errorListMenusResponse = getError(listMenusResponse)

            if (!errorListMenusResponse) {
                setListMenus(listMenusResponse.data.accountFunctionalitiesMenuPermission.menus)
            }
        } else {
            setListMenus([])
        }
    }

    async function handleOpenModalSelectCompany() {
        modal.open("modalSelectCompany")

        const listAccountantOfficeCompaniesResponse = await Controller.listAccountantOfficeCompanies(accountantOfficeSession.ID)
        const errListAccountantOfficeCompaniesResponse = getError(listAccountantOfficeCompaniesResponse)

        if (!errListAccountantOfficeCompaniesResponse) {
            let companiesID = []

            listAccountantOfficeCompaniesResponse.data.accountantOfficeCompanies.accountantOfficeCompanies.map((accountantOfficeCompany) => {
                companiesID.push(accountantOfficeCompany.companyID)
            })

            const listCompaniesBrazilResponse = await Controller.listCompaniesBrazilByIDs(companiesID)
            const errListCompaniesBrazilResponse = getError(listCompaniesBrazilResponse)

            if (!errListCompaniesBrazilResponse) {
                setListCompaniesBrazil(listCompaniesBrazilResponse.data.companiesBrazilByIDs.companiesBrazil)
            }
        }
    }

    async function handleSelectCompany(companyID) {
        const companyBrazilResponse = await Controller.getCompanyBrazil(companyID)
        const errCompanyBrazilResponse = getError(companyBrazilResponse)

        if (!errCompanyBrazilResponse) {
            const companyBrazil = companyBrazilResponse.data.companyBrazil.companyBrazil

            dispatch(companyAdd(companyBrazil))

            modal.close("modalSelectCompany")
            toast.message("Você está na empresa " + companyBrazil.socialName)
        }
    }

    return (
        <NavBarRender
            isLogged={isLogged}
            planStatusID={planStatusID}
            listMenus={listMenus}
            listCompaniesBrazil={listCompaniesBrazil}
            handleOpenModalSelectCompany={handleOpenModalSelectCompany}
            handleSelectCompany={handleSelectCompany} />
    )
}

export { NavBar }