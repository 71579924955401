import React from 'react'
import './style.css'

function Footer(props) {
    let buttonNameClose = "Fechar"
    if (props.buttonNameClose) {
        buttonNameClose = props.buttonNameClose
    }

    return (
        <div className="buttons">
            {props.footer}
            <button className="button is-rounded is-light" onClick={props.close}>{buttonNameClose}</button>
        </div>
    )
}

function Modal(props) {

    function close() {
        var element = document.getElementById(props.modalName)
        element.className = element.className.replace(/\bis-active\b/g, "")
    }

    return (
        <div className="modal" id={props.modalName}>
            <div className="modal-background"></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{props.modalTitle}</p>
                    <button className="delete" aria-label="close" onClick={close}></button>
                </header>
                <section className="modal-card-body">
                    {props.children}
                </section>
                <footer className="modal-card-foot">
                    <Footer footer={props.footer} close={close} buttonNameClose={props.buttonNameClose} />
                </footer>
            </div>
        </div>
    )
}

export {
    Modal
}