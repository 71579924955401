import React from 'react'
import { MenuDesktop } from './MenuDesktop'
import { ModalSelectCompany } from "./ModalSelectCompany"

function NavBarLogged({ listMenus, listCompaniesBrazil, handleOpenModalSelectCompany, handleSelectCompany }) {

    function menuBurger() {
        const el = document.getElementsByClassName("navbar-burger")[0]

        const target = el.dataset.target;
        const $target = document.getElementById(target);

        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');
    }

    return (
        <div>
            <ModalSelectCompany
                name="modalSelectCompany"
                listCompaniesBrazil={listCompaniesBrazil}
                handleSelectCompany={handleSelectCompany} />

            <nav className="navbar is-transparent" role="navigation" aria-label="dropdown navigation">
                <div className="navbar-brand">
                    <a className="navbar-item">
                        <div style={{ fontSize: "18px", backgroundColor: "#265D26", padding: "2px 5px", color: "#fff" }}>B3BNB</div>
                        <div style={{ marginLeft: "5px", padding: "5px", color: "#000" }}>Paperless</div>
                    </a>
                    <div className="navbar-burger burger" data-target="navbarExampleTransparentExample" onClick={menuBurger}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <MenuDesktop
                    listMenus={listMenus}
                    handleOpenModalSelectCompany={handleOpenModalSelectCompany} />
            </nav>
        </div>
    )
}

export {
    NavBarLogged
}