import React from "react"
import { AvatarWord } from "./AvatarWord"

const AvatarRender = ({
    name }) => (
    <AvatarWord
        name={name} />
)

export {
    AvatarRender
}
