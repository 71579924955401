import React from 'react'
import './style.css'

const Container = (props) => (
    <div className="container container-custom" >
        <div className="columns">
            <div className="column">

            </div>
            <div className="column is-12">
                {props.children}
            </div>
            <div className="column">

            </div>
        </div>
    </div >
)

export { Container }
