// const b3bnbAccountCookieApiV1Config = {
//     URL: 'http://ac.localhost.com:8002',
// }

// const inboxBusinessReportBrazilRestApiV1Config = {
//     URL: 'http://localhost:8036/v1',
// }

// const govNativeSaleNFCeReportBrazilRestApiV1Config = {
//     URL: 'http://localhost:8038/v1',
// }

const b3bnbAccountCookieApiV1Config = {
    URL: 'https://accountcookie.b3bnb.com',
}

const inboxBusinessReportBrazilRestApiV1Config = {
    URL: 'https://apis.b3bnb.com/inbox-business-report-brazil-rest-api/v1',
}

const govNativeSaleNFCeReportBrazilRestApiV1Config = {
    URL: 'https://apis.b3bnb.com/gov-native-sale-nfce-report-brazil-rest-api/v1',
}

export {
    b3bnbAccountCookieApiV1Config,
    inboxBusinessReportBrazilRestApiV1Config,
    govNativeSaleNFCeReportBrazilRestApiV1Config,
}
