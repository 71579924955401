import { getToken } from '../../../../../../infrastructure/api/rest/security_cookie'
import {
    graphqlB3bnbAccountApiV1, graphqlB3bnbAccountPlanApiV1,
    graphqlB3bnbCompanyBrazilApiV1, graphqlPaperlessAccountantOfficeBrazilApiV1
} from '../../../../../../infrastructure/api/graphql/graphql'
import {
    accountByTokenQuery, accountPlanPaperlessBrazilByAccountQuery,
    companiesBrazilQuery, accountantOfficeQuery, accountantOfficeCompaniesQuery,
    companyBrazilQuery
} from './graphql/query'

async function getCookieToken() {
    return await getToken()
}

async function getAccountByToken(token) {
    const payload = {
        token
    }

    return await graphqlB3bnbAccountApiV1(accountByTokenQuery, payload)
}

async function geAccountPlanPaperlessBrazilByAccount(accountID) {
    const payload = {
        accountID
    }

    return await graphqlB3bnbAccountPlanApiV1(accountPlanPaperlessBrazilByAccountQuery, payload)
}

async function listCompaniesBrazil(accountID) {
    const payload = {
        accountID
    }

    return await graphqlB3bnbCompanyBrazilApiV1(companiesBrazilQuery, payload)
}

async function getAccountantOffice(accountID) {
    const payload = {
        accountID
    }

    return await graphqlPaperlessAccountantOfficeBrazilApiV1(accountantOfficeQuery, payload)
}

async function listAccountantOfficeCompanies(accountantOfficeID) {
    const payload = {
        accountantOfficeID
    }

    return await graphqlPaperlessAccountantOfficeBrazilApiV1(accountantOfficeCompaniesQuery, payload)
}

async function getCompanyBrazil(ID) {
    const payload = {
        ID
    }

    return await graphqlB3bnbCompanyBrazilApiV1(companyBrazilQuery, payload)
}

const Controller = {
    getCookieToken,
    getAccountByToken,
    geAccountPlanPaperlessBrazilByAccount,
    listCompaniesBrazil,
    getAccountantOffice,
    listAccountantOfficeCompanies,
    getCompanyBrazil
}

export {
    Controller
}