const accountByTokenQuery = `
query($token:String!){
    accountByToken(token:$token){
        account{
            ID,
            name,
            email
        },
        error{
            code,
            message
        }
    }
}
`

const accountPlanPaperlessBrazilByAccountQuery = `
query($accountID:ID!){
    accountPlanPaperlessBrazilByAccount(accountID:$accountID){
        accountPlan{
            ID,
            planStatusID,
            accountPlanInvoiceID
        },
        error{
            code,
            message
        }
    }
}
`

const accountantOfficeQuery = `
query($accountID:String!){
    accountantOffice(accountID:$accountID){
        accountantOffice{
            ID
        },
        error{
            code,
            message
        }
    }
}
`

const companiesBrazilQuery = `
query($accountID:ID!){
    companiesBrazil(accountID:$accountID){
        companiesBrazil{
            ID,
            document,
            socialName
        },
        error{
            code,
            message
        }
    }
}
`

const accountantOfficeCompaniesQuery = `
query($accountantOfficeID:ID!){
  accountantOfficeCompanies(accountantOfficeID:$accountantOfficeID){
    accountantOfficeCompanies{
      companyID
    },
    error{
      code,
      message
    }
  }
}
`

const companyBrazilQuery = `
query($ID:ID!){
    companyBrazil(ID:$ID){
        companyBrazil{
            ID,
            socialName,
            document
        },
        error{
            code,
            message
        }
    }
}
`

export {
    accountByTokenQuery,
    accountPlanPaperlessBrazilByAccountQuery,
    accountantOfficeQuery,
    companiesBrazilQuery,
    accountantOfficeCompaniesQuery,
    companyBrazilQuery
}