import React from 'react'
import { Routes } from '../../routes/routes'
import { Container } from '../Container/Container'
import { PageLoad } from '../../components/PageLoad/PageLoad'
import { NavBar } from '../NavBar/NavBar'
import { ConnectedRouter } from 'connected-react-router'

const AppRender = ({ props }) => (
    <div>
        <PageLoad />
        <NavBar />
        <Container>
            <ConnectedRouter history={props.history}>
                <Routes />
            </ConnectedRouter>
        </Container>
    </div>
)

export { AppRender }