import Snackbar from 'node-snackbar'
import 'node-snackbar/dist/snackbar.min.css'

const message = (text) => {
    Snackbar.show({ text, showAction: true, actionText: 'Fechar', actionTextColor: '#8ab4f8', pos: 'bottom-center' })
}

const messageModal = (text) => {
    Snackbar.show({ text, showAction: true, actionText: 'Fechar', backgroundColor: '#BBCDEE', textColor: '#363636', actionTextColor: '#000', pos: 'bottom-center' })
}

const toast = {
    message,
    messageModal
}

export { toast }
