const accountFunctionalitiesMenuPermissionQuery = `
query($accountID:ID!,$accountPlanID:ID!){
    accountFunctionalitiesMenuPermission(accountID:$accountID,accountPlanID:$accountPlanID){
      menus{
        ID,
        name,
        submenus{
          ID,
          name,
          functionalities{
            name,
            path
          }
        }
      },
      error{
        code,
        message
      }
  
    }
  }
`

const accountantOfficeCompaniesQuery = `
query($accountantOfficeID:ID!){
  accountantOfficeCompanies(accountantOfficeID:$accountantOfficeID){
    accountantOfficeCompanies{
      companyID
    },
    error{
      code,
      message
    }
  }
}
`

const companiesBrazilByIDsQuery = `
query($companiesID:[String]!){
  companiesBrazilByIDs(companiesID:$companiesID){
    companiesBrazil{
      ID,
      socialName,
      document
    },
    error{
      code,
      message
    }
  }
}
`

const companyBrazilQuery = `
query($ID:ID!){
    companyBrazil(ID:$ID){
        companyBrazil{
            ID,
            socialName,
            document
        },
        error{
            code,
            message
        }
    }
}
`

export {
  accountFunctionalitiesMenuPermissionQuery,
  accountantOfficeCompaniesQuery,
  companiesBrazilByIDsQuery,
  companyBrazilQuery
}