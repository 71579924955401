import React from 'react'
import { Modal } from '../../components/Modal/Modal'

const Footer = () => (
    <>
    </>
)

const ModalSelectCompany = (props) => (
    <Modal modalName={props.name} modalTitle="Clientes" footer={<Footer />} >
        {props.listCompaniesBrazil.map((companyBrazil) =>
            <label key={companyBrazil.ID} className="radio" style={{ width: "100%" }}>
                <div className="card select-company" style={{ marginBottom: "10px" }}>
                    <div className="card-content">
                        <div className="media">
                            <div className="media-left">
                                <div className="control">
                                    <input type="radio" name="answer" onClick={_ => props.handleSelectCompany(companyBrazil.ID)} />
                                </div>
                            </div>
                            <div className="media-content">
                                <p className="title is-4">{companyBrazil.socialName}</p>
                                <p className="subtitle is-6">{companyBrazil.document}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </label>
        )}
    </Modal>
)

export {
    ModalSelectCompany
}