import React from 'react'
import { useSelector } from 'react-redux'
import { PageLoadRender } from './PageLoadRender'

function PageLoad() {
    const isLoad = useSelector(state => state.session.isLoad)

    return (
        <PageLoadRender isLoad={isLoad} />
    )
}

export { PageLoad }