import React, { Suspense, lazy } from 'react'
import { Switch, Route } from 'react-router-dom'

const CreateAccountPlanFlowPage = lazy(() => import('../pages/CreateAccountPlanFlowPage/CreateAccountPlanFlowPage'))
const CreateCompanyChoisePersonTypeFlowPage = lazy(() => import('../pages/CreateCompanyChoisePersonTypeFlowPage/CreateCompanyChoisePersonTypeFlowPage'))
const CreatePersonalCompanyFlowPage = lazy(() => import('../pages/CreatePersonalCompanyFlowPage/CreatePersonalCompanyFlowPage'))
const CreateCompanyFlowPage = lazy(() => import('../pages/CreateCompanyFlowPage/CreateCompanyFlowPage'))
const HomePage = lazy(() => import('../pages/HomePage/HomePage'))
const NoMatchPage = lazy(() => import('../pages/NoMatchPage/NoMatchPage'))
const AccountPlanFreeExpiredPage = lazy(() => import('../pages/AccountPlanFreeExpiredPage/AccountPlanFreeExpiredPage'))
const AccountPlanBlockedOverdueInvoicePage = lazy(() => import('../pages/AccountPlanBlockedOverdueInvoicePage/AccountPlanBlockedOverdueInvoicePage'))
const AccountPlanBlockedPage = lazy(() => import('../pages/AccountPlanBlockedPage/AccountPlanBlockedPage'))
const DailyCashFlowPage = lazy(() => import('../pages/DailyCashFlowPage/DailyCashFlowPage'))
const MonthlyCashFlowPage = lazy(() => import('../pages/MonthlyCashFlowPage/MonthlyCashFlowPage'))
const MonthlyAccountingClosingPage = lazy(() => import('../pages/MonthlyAccountingClosingPage/MonthlyAccountingClosingPage'))
const WelcomeSendAccountantOfficeInvitationPage = lazy(() => import('../pages/WelcomeSendAccountantOfficeInvitationPage/WelcomeSendAccountantOfficeInvitationPage'))
const CashFlowReportPage = lazy(() => import('../pages/CashFlowReportPage/CashFlowReportPage'))

const Routes = () => (
    <Suspense fallback={<div>Carregando...</div>}>
        <Switch>
            <Route exact path='/flow/createAccountPlan' component={CreateAccountPlanFlowPage} />
            <Route exact path='/flow/createCompanyChoisePersonType' component={CreateCompanyChoisePersonTypeFlowPage} />
            <Route exact path='/flow/createCompany' component={CreateCompanyFlowPage} />
            <Route exact path='/flow/createPersonalCompany' component={CreatePersonalCompanyFlowPage} />
            <Route exact path='/home' component={HomePage} />
            <Route exact path='/accountPlanFreeExpired' component={AccountPlanFreeExpiredPage} />
            <Route exact path='/accountPlanBlockedOverdueInvoice' component={AccountPlanBlockedOverdueInvoicePage} />
            <Route exact path='/accountPlanBlocked' component={AccountPlanBlockedPage} />
            <Route exact path='/dailyCashFlow' component={DailyCashFlowPage} />
            <Route exact path='/monthlyCashFlow' component={MonthlyCashFlowPage} />
            <Route exact path='/monthlyAccountingClosing' component={MonthlyAccountingClosingPage} />
            <Route exact path='/welcomeSendAccountantOfficeInvitation' component={WelcomeSendAccountantOfficeInvitationPage} />
            <Route exact path='/cashFlowReport' component={CashFlowReportPage} />
            <Route exact path='*' component={NoMatchPage} />
        </Switch>
    </Suspense>
)

export { Routes }